import React from 'react'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'

export const Fade = ({ children, in: inProp, timeout = 0 }) => {
  const duration = 1000 + timeout

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0
  }

  const transitionStyles = {
    entering: { opacity: 1, transform: 'translateY(0px)' },
    entered: { opacity: 1, transform: 'translateY(0px)' },
    exiting: { opacity: 0, transform: 'translateY(20px)' },
    exited: { opacity: 0, transform: 'translateY(20px)' }
  }

  return (
    <Transition in={inProp} timeout={duration}>
      {(state) =>
        React.cloneElement(children, {
          style: {
            ...defaultStyle,
            ...transitionStyles[state]
          }
        })
      }
    </Transition>
  )
}

Fade.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
  in: PropTypes.bool
}
