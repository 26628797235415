import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const ImagesQuery = ({ render }) => (
  <StaticQuery
    query={graphql`
      query {
        iphoneBrandie: file(relativePath: { eq: "images/iphone-brandie.png" }) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iphoneLogin: file(relativePath: { eq: "images/iphone-login.png" }) {
          childImageSharp {
            fluid(maxWidth: 920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iphoneContent: file(relativePath: { eq: "images/iphone-content.png" }) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iphoneReward: file(relativePath: { eq: "images/iphone-reward.png" }) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        macbook: file(relativePath: { eq: "images/macbook.png" }) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brandieWhitelabelFlow: file(relativePath: { eq: "images/brandie-whitelabel-flow.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        saasUgcChart: file(relativePath: {eq: "images/saas-ugc-chart.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        dashboardScreenshot: file(relativePath: {eq: "images/dashboard-screenshot.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        howToAddCaption: file(relativePath: {eq: "images/how-to-add-caption.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        howToAddStickers1: file(relativePath: {eq: "images/how-to-add-stickers-1.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        howToAddStickers2: file(relativePath: {eq: "images/how-to-add-stickers-1.png"}) {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        macbookInsights: file(
          relativePath: { eq: "images/macbook-insights.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        macbookScale: file(
          relativePath: { eq: "images/macbook-scale.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        macbookAudience: file(
          relativePath: { eq: "images/macbook-audience.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero: file(relativePath: { eq: "images/hero-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroThree: file(relativePath: { eq: "images/hero-3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroFour: file(relativePath: { eq: "images/hero-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroFive: file(relativePath: { eq: "images/hero-5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroSix: file(relativePath: { eq: "images/hero-6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroAmbassadors: file(
          relativePath: { eq: "images/hero-ambassadors.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        reach: file(
          relativePath: { eq: "images/reach.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroBrands: file(relativePath: { eq: "images/hero-brands.png" }) {
          childImageSharp {
            fluid(maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ambassador: file(relativePath: { eq: "images/ambassador.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        brand: file(relativePath: { eq: "images/brand.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 680, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        whatWeDo: file(relativePath: { eq: "images/what-we-do.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        privacyPolicy: file(relativePath: { eq: "images/privacy.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        terms: file(relativePath: { eq: "images/terms.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        case1: file(relativePath: { eq: "images/case-airindia.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        case2: file(relativePath: { eq: "images/case-fastrack.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        case3: file(relativePath: { eq: "images/case-puma.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        case4: file(relativePath: { eq: "images/case-raw-pressery.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiPeace: file(relativePath: { eq: "images/emoji-peace.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiHighfive: file(relativePath: { eq: "images/emoji-highfive.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiLike: file(relativePath: { eq: "images/emoji-like.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiTerms: file(relativePath: { eq: "images/emoji-terms.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiPrivacy: file(relativePath: { eq: "images/emoji-privacy.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiMonocle: file(relativePath: { eq: "images/emoji-monocle.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiFollower: file(relativePath: { eq: "images/emoji-follower.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiStats: file(relativePath: { eq: "images/emoji-stats.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiCamera: file(relativePath: { eq: "images/emoji-camera.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiMobile: file(relativePath: { eq: "images/emoji-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiRocket: file(relativePath: { eq: "images/emoji-rocket.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiPhoneArrow: file(relativePath: { eq: "images/emoji-phone-arrow.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiBarchart: file(relativePath: { eq: "images/emoji-barchart.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiGreenCheck: file(relativePath: { eq: "images/emoji-green-check.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiSpeechBalloon: file(relativePath: { eq: "images/emoji-speech-balloon.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiHashtag: file(relativePath: { eq: "images/emoji-hashtag.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiTrophy: file(relativePath: { eq: "images/emoji-trophy.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        emojiLaptop: file(relativePath: { eq: "images/emoji-laptop.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        quote1: file(relativePath: { eq: "images/quote1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        quote2: file(relativePath: { eq: "images/quote2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        quote3: file(relativePath: { eq: "images/quote3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gridImg: file(relativePath: { eq: "images/grid-images.png" }) {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gridImgSm: allFile(
          filter: { relativePath: { glob: "images/grid-images-mobile-*.png" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return render(data)
    }}
  />
)

ImagesQuery.propTypes = {
  render: PropTypes.func.isRequired
}

export default ImagesQuery
