import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Link from '@input-output-hk/front-end-core-components/components/Link'
import { analytics } from '@input-output-hk/front-end-core-libraries'
import { Grid, Box, Collapse, ClickAwayListener } from '@material-ui/core'
import { HamburgerCollapse } from 'react-animated-burgers'

import GlobalContentQuery from '../queries/GlobalContentQuery'
import LogoBrandieSvg from '../../resources/images/svg/inline/logo_brandie.svg'
import Button from './Button'
import AccountSvg from '../../resources/images/svg/inline/account.svg'

const StyledAppBar = styled(AppBar)`
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.97);
  top: 14px;
  border-radius: 35px;
  min-height: 65px;
  width: 270px;
  left: 50%;
  transform: translateX(-50%);

  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);

  a {
    color: black;

    &:hover {
      color: ${(props) => props.theme.colors.primary.main};
    }
  }
`

const Logo = styled(LogoBrandieSvg)`
  height: 30px;
  fill: ${(props) => props.theme.colors.primary.main};

  @media (min-width: 1200px) {
    height: 35px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  height: 26px;
  width: 26px;
  cursor: pointer;

  svg {
    height: 26px;
    width: 26px;
    transition: 0.3s ease-out;
  }

  div {
    position: absolute;
    font-size: 10px;
    white-space: nowrap;
    margin-top: -4px;
    margin-left: -2px;
    transition: 0.3s ease-out;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }

    div {
      color: ${(props) => props.theme.colors.primary.main};
    }
  }

  &:active {
    transform: scale(0.95);
  }
`
const StyledHamburger = styled(HamburgerCollapse)`
  outline: none;
  padding: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-out;

  span,
  span:after,
  span:before {
    height: 3px;
    width: 21px;
    background: rgb(171, 170, 166);
  }

  ${(props) =>
    !props.isActive
      ? `
    span::after {
      top: -14px;
    }

    span::before {
      top: -7px;
    }
    `
      : ''}

  &:hover {
    transform: scale(1.1);
  }
`
const StyledLink = styled(Link)`
  font-size: 1.5rem;
  @media (min-width: 600px) {
    font-size: initial;
  }
`

// const DOWNLOAD_URL = 'http://onelink.to/k9rjj4'

const Header = () => {
  const [ expanded, setExpanded ] = useState(false)
  const handleClickAway = (e) => {
    if (expanded) {
      analytics.click({
        category: 'header_outside',
        label: 'close',
        event: e
      })
      setExpanded(false)
    }
  }
  const toggleExpansion = useCallback((e) => {
    analytics.click({
      category: 'header_hamburger',
      label: !expanded ? 'expand' : 'close',
      event: e
    })
    setExpanded((expanded) => !expanded)
  }, [])

  return (
    <GlobalContentQuery
      render={(content) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledAppBar position='fixed'>
            <Box
              m={0}
              height='65px'
              display='flex'
              justifyContent='space-around'
              alignItems='center'
            >
              <IconWrapper>
                <StyledLink
                  tracking={{ label: 'header_login' }}
                  href='https://dashboard.brandie.in/landing'
                >
                  <AccountSvg /> {expanded && <div>Log in</div>}
                </StyledLink>
              </IconWrapper>

              <StyledLink tracking={{ label: 'header_logo' }} href='/'>
                <Logo />
              </StyledLink>

              <StyledHamburger
                barColor='#b4b2ae'
                buttonWidth={26}
                isActive={expanded}
                toggleButton={toggleExpansion}
              />
            </Box>

            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <Box m={2} pt={2}>
                <Grid
                  container
                  spacing={3}
                  direction='column'
                  justify='center'
                  alignItems='center'
                >

                  <Grid item>
                    <StyledLink
                      tracking={{ label: 'header_crowd_marketing' }}
                      href='/crowd-marketing'
                    >
                      {content.link_crowd_marketing}
                    </StyledLink>
                  </Grid>

                  <Grid item>
                    <StyledLink
                      tracking={{ label: 'header_terms' }}
                      href='/ambassadors'
                    >
                      {content.link_ambassadors}
                    </StyledLink>
                  </Grid>

                  <Grid item>
                    <StyledLink
                      tracking={{ label: 'header_privacy' }}
                      href='/brands'
                    >
                      {content.link_brands}
                    </StyledLink>
                  </Grid>

                  <Grid item>
                    <StyledLink
                      tracking={{ label: 'header_about' }}
                      href='/about'
                    >
                      {content.link_about}
                    </StyledLink>
                  </Grid>

                  <Grid item>
                    <StyledLink
                      tracking={{ label: 'header_saas' }}
                      href='/'
                    >
                      {content.link_saas}
                    </StyledLink>
                  </Grid>

                </Grid>
                <Box mt={5}>
                  <StyledLink
                    tracking={{ label: 'header_download' }}
                    href='/'
                  >
                    <Button>Request Demo</Button>
                  </StyledLink>
                </Box>
              </Box>
            </Collapse>
          </StyledAppBar>
        </ClickAwayListener>
      )}
    />
  )
}

export default Header
