import { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import Observer from '../components/Observer'

const Visible = ({ render, updateOnce, onChange }) => {
  const [ isVisible, setIsVisible ] = useState(false)
  const ref = useRef(null)
  const observer = useContext(Observer.Context)

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.unobserve(ref.current)
  }, [ ref.current ])

  useEffect(() => {
    const entry = observer.getEntry(ref.current)
    if (entry && entry.intersectionRatio > 0) {
      if (!isVisible) {
        onChange(true)
        setIsVisible(true)
      }
      if (updateOnce) observer.unobserve(ref.current)
    } else if (entry) {
      if (isVisible) {
        onChange(false)
        setIsVisible(false)
      }
    }
  }, [ observer.entries ])

  return render(isVisible, ref)
}
Visible.propTypes = {
  render: PropTypes.func.isRequired,
  updateOnce: PropTypes.bool,
  onChange: PropTypes.func
}
Visible.defaultProps = {
  updateOnce: true,
  onChange: () => {}
}

export default Visible
