import React from 'react'
import Img from 'gatsby-image'
import ImagesQuery from '../queries/ImagesQuery'

// eslint-disable-next-line react/prop-types
export const genOptImg = (fluidSelector) => ({ className, style }) => (
  <ImagesQuery
    render={(images) => (
      <Img className={className} style={style} fluid={fluidSelector(images)} />
    )}
  />
)

export default {
  Hero: genOptImg((d) => d.hero.childImageSharp.fluid),
  HeroThree: genOptImg((d) => d.heroThree.childImageSharp.fluid),
  HeroFour: genOptImg((d) => d.heroFour.childImageSharp.fluid),
  HeroFive: genOptImg((d) => d.heroFive.childImageSharp.fluid),
  HeroAmbassadors: genOptImg((d) => d.heroAmbassadors.childImageSharp.fluid),
  WhatWeDo: genOptImg((d) => d.whatWeDo.childImageSharp.fluid),
  Reach: genOptImg((d) => d.Reach.childImageSharp.fluid),
  PrivacyPolicy: genOptImg((d) => d.privacyPolicy.childImageSharp.fluid),
  Terms: genOptImg((d) => d.terms.childImageSharp.fluid),
  IphoneBrandie: genOptImg((d) => d.iphoneBrandie.childImageSharp.fluid),
  IphoneLogin: genOptImg((d) => d.iphoneLogin.childImageSharp.fluid),
  IphoneContent: genOptImg((d) => d.iphoneContent.childImageSharp.fluid),
  IphoneReward: genOptImg((d) => d.iphoneReward.childImageSharp.fluid),
  EmojiPeace: genOptImg((d) => d.emojiPeace.childImageSharp.fluid),
  EmojiLike: genOptImg((d) => d.emojiLike.childImageSharp.fluid),
  EmojiMonocle: genOptImg((d) => d.emojiMonocle.childImageSharp.fluid),
  EmojiHighfive: genOptImg((d) => d.emojiHighfive.childImageSharp.fluid),
  EmojiTerms: genOptImg((d) => d.emojiTerms.childImageSharp.fluid),
  EmojiPrivacy: genOptImg((d) => d.emojiPrivacy.childImageSharp.fluid),
  EmojiFollower: genOptImg((d) => d.emojiFollower.childImageSharp.fluid),
  EmojiMobile: genOptImg((d) => d.emojiMobile.childImageSharp.fluid),
  EmojiRocket: genOptImg((d) => d.emojiRocket.childImageSharp.fluid),
  EmojiPhoneArrow: genOptImg((d) => d.emojiPhoneArrow.childImageSharp.fluid),
  EmojiCamera: genOptImg((d) => d.emojiCamera.childImageSharp.fluid),
  EmojiStats: genOptImg((d) => d.emojiStats.childImageSharp.fluid),
  EmojiBarchart: genOptImg((d) => d.emojiBarchart.childImageSharp.fluid),
  EmojiGreenCheck: genOptImg((d) => d.emojiGreenCheck.childImageSharp.fluid),
  EmojiSpeechBalloon: genOptImg((d) => d.emojiSpeechBalloon.childImageSharp.fluid),
  EmojiHashtag: genOptImg((d) => d.emojiHashtag.childImageSharp.fluid),
  EmojiTrophy: genOptImg((d) => d.emojiTrophy.childImageSharp.fluid),
  EmojiLaptop: genOptImg((d) => d.emojiLaptop.childImageSharp.fluid),
  GridImages: genOptImg((d) => d.gridImg.childImageSharp.fluid),
  GridImagesSm1: genOptImg((d) => d.gridImgSm.nodes[0].childImageSharp.fluid),
  GridImagesSm2: genOptImg((d) => d.gridImgSm.nodes[1].childImageSharp.fluid),
  Macbook: genOptImg((d) => d.macbook.childImageSharp.fluid),
  MacbookInsights: genOptImg((d) => d.macbookInsights.childImageSharp.fluid),
  MacbookAudience: genOptImg((d) => d.macbookAudience.childImageSharp.fluid),
  MacbookScale: genOptImg((d) => d.macbookScale.childImageSharp.fluid),
  BrandieWhitelabelFlow: genOptImg((d) => d.brandieWhitelabelFlow.childImageSharp.fluid),
  SaaSUGCChart: genOptImg((d) => d.saasUgcChart.childImageSharp.fluid),
  DashboardScreenshot: genOptImg((d) => d.dashboardScreenshot.childImageSharp.fluid),
  HowToAddCaption: genOptImg((d) => d.howToAddCaption.childImageSharp.fluid),
  HowToAddStickers1: genOptImg((d) => d.howToAddStickers1.childImageSharp.fluid),
  HowToAddStickers2: genOptImg((d) => d.howToAddStickers2.childImageSharp.fluid)
}
