const baseConfig = {
  type: 'light',
  colors: {
    primary: {
      main: '#66BF7E',
      light: '#9ED5AC',
      dark: '#43985A',
      contrastText: '#000'
    },
    secondary: {
      main: '#6666bd',
      light: 'rgb(250, 56, 56)',
      dark: 'rgb(196, 0, 0)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    text: {
      primary: '#3E413F',
      secondary: 'white',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    background: {
      default: '#fff'
    }
  },
  overrides: {},
  typography: {
    fontFamily: 'Poppins, sans-serif',

    googleFontsURL:
      'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,800;1,300&display=swap',
    baseFontSize: 16,
    lineHeight: 1.6,
    letterSpacing: 0.1,
    fontWeight: 400,
    h1: {
      fontSize: 4.488,
      fontWeight: 900,
      lineHeight: 1.15,
      margin: '0 0 1.3rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 3.488
      },
      '@media (max-width:600px)': {
        fontSize: 2.888
      }
    },
    h2: {
      fontSize: 3.674,
      fontWeight: 900,
      lineHeight: 1.15,
      margin: '0 0 1.3rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 2.674
      },
      '@media (max-width:600px)': {
        fontSize: 1.874
      }
    },
    h3: {
      fontSize: 2.428,
      fontWeight: 900,
      lineHeight: 1.15,
      margin: '0 0 1.3rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 1.828
      },
      '@media (max-width:600px)': {
        fontSize: 1.628
      }
    },
    h4: {
      fontSize: 1.44,
      fontWeight: 900,
      lineHeight: 1.15,
      margin: '0 0 1.1rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 1.24
      },
      '@media (max-width:600px)': {
        fontSize: 1.04
      }
    },
    h5: {
      fontSize: 1.2,
      fontWeight: 900,
      lineHeight: 1.1,
      margin: '0 0 1.1rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 1
      }
    },
    h6: {
      fontSize: 1,
      fontWeight: 900,
      lineHeight: 1.1,
      margin: '0 0 1.1rem',
      textTransform: 'none',
      '@media (max-width:960px)': {
        fontSize: 0.8
      }
    },
    body: {
      fontSize: 1,
      fontWeight: 300,
      letterSpacing: 0.05,
      lineHeight: 1.5,
      overflow: 'hidden',
      '@media (max-width:600px)': {
        fontSize: 0.8
      },
      '@media (min-width:2049px)': {
        fontSize: 1.125
      }
    },
    small: {
      fontSize: 0.8,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 1.5
    },
    button: {
      fontSize: 1,
      fontWeight: 300,
      letterSpacing: 0.125,
      lineHeight: 1,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: 0.8,
      fontWeight: 300,
      letterSpacing: 0.04,
      lineHeight: 1.8,
      textTransform: 'none'
    }
  }
}

const mergeConfig = (base, config) => {
  Object.keys(config).forEach((key) => {
    if (typeof config[key] === 'object' && !Array.isArray(config[key])) {
      base[key] = {
        ...base[key],
        ...mergeConfig({ ...base[key] }, config[key])
      }
    } else {
      base[key] = config[key]
    }
  })

  return base
}

export const extendDefaultTheme = (theme) => {
  return mergeConfig({ ...baseConfig }, theme)
}
