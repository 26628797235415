import React, { createContext, Component } from 'react'
import PropTypes from 'prop-types'

const Context = createContext()
const Consumer = Context.Consumer

class Provider extends Component {
  observer = null
  state = {
    entries: []
  }

  componentWillUnmount () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  observerCallback = (entries, observer) => {
    this.setState({ entries })
  }

  getObserver () {
    if (!this.observer) {
      this.observer = new window.IntersectionObserver(this.observerCallback)
    }
    return this.observer
  }

  render () {
    return (
      <Context.Provider
        value={{
          entries: this.state.entries,
          observe: (target) => this.getObserver().observe(target),
          unobserve: (target) => this.getObserver().unobserve(target),
          getEntry: (target) =>
            this.state.entries.find((e) => e.target === target)
        }}
      >
        {this.props.children}
      </Context.Provider>
    )
  }
}

Provider.propTypes = {
  children: PropTypes.node.isRequired
}

export { Provider, Consumer, Context }

export default {
  Provider,
  Consumer,
  Context
}
