module.exports = [{
      plugin: require('../node_modules/@rhysforyou/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brandie","short_name":"Brandie","start_url":"/","background_color":"#66BF7E","theme_color":"#66BF7E","display":"fullscreen","icon":"resources/images/brandie-logo-circle.png","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6eb4cf70d5a5417d32d558cba4d0a529"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"391644241869314"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
