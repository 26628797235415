import React from 'react'
import Link from '@input-output-hk/front-end-core-components/components/Link'
import styled from 'styled-components'
import { Fade } from './Transitions'
import Visible from './Visible'
import GlobalContentQuery from '../queries/GlobalContentQuery'
import LogoBrandieSvg from '../../resources/images/svg/inline/logo_brandie.svg'
// import TwitterSvg from '../../resources/images/svg/inline/social-twitter.svg'
import FacebookSvg from '../../resources/images/svg/inline/social-facebook.svg'
import LinkedinSvg from '../../resources/images/svg/inline/social-linkedin.svg'
import InstagramSvg from '../../resources/images/svg/inline/social-instagram.svg'
import MailSvg from '../../resources/images/svg/inline/mail.svg'

const Container = styled.div`
  min-height: 100vh;
  padding: 10rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  fill: ${(props) => props.theme.colors.text.secondary};
  color: ${(props) => props.theme.colors.text.secondary};
  a {
    color: ${(props) => props.theme.colors.text.secondary};
  }
  p,
  span {
    margin: 0 0 10px 0;
  }
  span {
    opacity: 0.6;
  }
  background-color: ${(props) => props.theme.colors.primary.main};
  position: relative;
  z-index: 1;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  a {
    margin-bottom: 24px;
    font-size: 1.9rem;
    opacity: 0.95;
    transition: 0.3s ease-out;
    color: white;

    &:hover {
      transform: scale(1.1);
      opacity: 1;
      color: white;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  @media (min-width: 1200px) {
    flex-direction: row;

    a {
      margin-right: 42px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 38px 0 48px 0;

  a {
    margin: 0 16px;
    transition: 0.3s ease-out;

    &:hover {
      transform: scale(1.1);
      opacity: 1;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
`

// const Mail = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   margin-top: 42px;
//   opacity: 0.95;
//   transition: 0.3s ease-out;

//   svg {
//     margin-right: 6px;
//     width: 16px;
//     fill: white;
//   }

//   a {
//     color: white;

//     &:hover {
//       color: white;
//     }
//   }

//   &:hover {
//     opacity: 1;
//     transform: scale(1.1);
//   }

//   &:active {
//     transform: scale(0.95);
//   }
// `

const LogoSvg = styled(LogoBrandieSvg)`
  width: 230px;
  margin: 56px 0 18px;
`

const Footer = () => {
  return (
    <GlobalContentQuery
      render={(content) => (
        <Container>
          <Visible
            render={(isVisible, ref) => (
              <Fade in={isVisible}>
                <Links ref={ref}>
                  <Link
                    tracking={{ label: 'footer_terms' }}
                    href='/terms-of-use'
                  >
                    {content.link_terms}
                  </Link>
                  <Link
                    tracking={{ label: 'footer_privacy' }}
                    href='/privacy-policy'
                  >
                    {content.link_privacy}
                  </Link>
                  <Link tracking={{ label: 'footer_about' }} href='/about'>
                    {content.link_about}
                  </Link>
                </Links>
              </Fade>
            )}
          />
          <LogoSvg />
          <span>All Rights Reserved. 2020</span>
          <Social>
            <Link
              tracking={{ label: 'footer_facebook' }}
              href='https://www.facebook.com/BrandieApp'
            >
              <FacebookSvg />
            </Link>
            <Link
              tracking={{ label: 'footer_instagram' }}
              href='https://www.instagram.com/brandie.app/?hl=en'
            >
              <InstagramSvg />
            </Link>
            <Link
              tracking={{ label: 'footer_linkedin' }}
              href='https://www.linkedin.com/company/brandieapp/'
            >
              <LinkedinSvg />
            </Link>
            <Link
              tracking={{ label: 'footer_email' }}
              href='mailto:info@brandie.in?subject=Contact'
            >
              <MailSvg />
            </Link>
          </Social>
          <p>Skeppsbron 8, 111 30</p>
          <p>Stockholm, Sweden</p>
          {/* <Link
            tracking={{ label: 'footer_linkedin' }}
            href='mailto:info@brandie.in?subject=Contact'
          >
            <Mail>
              <MailSvg />
              info@brandie.in
            </Mail>
          </Link> */}
        </Container>
      )}
    />
  )
}

Footer.propTypes = {}

export default Footer
