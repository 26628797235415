import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  display: block;
  margin: 0 auto;
  padding: 20px 40px;
  border: 2px solid;
  border-radius: 90px;
  font-family: inherit;
  font-weight: 500;
  color: white;
  text-transform: none;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: 0.3s ease-out;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'transparent':
        return `
          background-color: transparent;
          border-color: white;
        `
      case 'large':
        return `
          background-color: ${theme.colors.primary.main};
          border-color: ${theme.colors.primary.main};
          font-size: 16px;

          @media (min-width: 600px) {
            font-size: 20px;
          }
        `
      default:
        return `
          background-color: ${theme.colors.primary.main};
          border-color: ${theme.colors.primary.main};
        `
    }
  }}

  &:disabled {
    cursor: auto;
    color: #dedede;
    background-color: #a0a0a0;
    border-color: #a0a0a0;
    pointer-events: none;
  }

  &:hover:enabled {
    z-index: 1;
    transform: scale(1.02);
    border: 2px solid ${(props) => props.theme.colors.primary.main};
    background-color: ${(props) => props.theme.colors.primary.main};
  }

  &:active:enabled {
    transform: scale(0.95);
    background-color: ${(props) => props.theme.colors.primary.dark};
    border-color: ${(props) => props.theme.colors.primary.dark};
  }
`

Button.propTypes = {
  variant: PropTypes.oneOf([ 'default', 'transparent', 'large' ])
}

Button.defaultProps = {
  variant: 'default'
}

export default Button
