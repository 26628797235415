import React from 'react'
import Container from '@material-ui/core/Container'
import { Box } from '@material-ui/core'
import Layout from '../components/Layout'
import OptImages from '../components/OptImages'
import NotFoundPageQuery from '../queries/NotFoundPageQuery'

import Link from '@input-output-hk/front-end-core-components/components/Link'
import Button from '../components/Button'

export default () => (
  <NotFoundPageQuery
    render={(content) => (
      <Layout>
        <Container maxWidth='lg'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            height='100vh'
          >
            <OptImages.EmojiMonocle style={{ width: '100px' }} />
            <h1>Hmmm...</h1>
            <p>404 - This page is doesn’t exist or is unavailable.</p>
            <br />
            <Link href='/'>
              <Button>Back to home</Button>
            </Link>
          </Box>
        </Container>
      </Layout>
    )}
  />
)
